import Header from "../../components/Common/Header";
import VibingHome from "../../components/HomeComponents/VibingHome";
import { motion } from "framer-motion";
import { animationVariants } from "../../hooks/animationVariants";

const Home = () => {
  return (
    <motion.div
      variants={animationVariants}
      initial="initial"
      animate="enter"
      exit="exit"
      className="w-full h-full"
    >
      <div className="absolute top-0 w-[100%] z-20 left-0">
        <Header />
      </div>
      <VibingHome />
    </motion.div>
  );
};

export default Home;
