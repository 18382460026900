import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

const TeamFosgui = () => {
  const { unityProvider } = useUnityContext({
    loaderUrl: "/public/GameJam/TeamFosgui/Build/Build.loader.js",
    dataUrl: "/public/components/GameJam/TeamFosgui/Build/Build.data",
    frameworkUrl: "/public/components/GameJam/TeamFosgui/Build/Build.framework.js",
    codeUrl: "/public/components/GameJam/TeamFosgui/Build/Build.wasm",
  });

  return (
        <Unity unityProvider={unityProvider}
        style={{ height: 720, width: 960 }}
        />
    );
}

export default TeamFosgui;