const duration = 0.8;

export const animationVariants = {
  initial: {
    opacity: 0,
    position: "absolute",
    y: 0,
  },
  enter: {
    opacity: 1,
    y: 0,
    position: "relative",
    transition: {
      duration: duration,
      // delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    position: "absolute",
    y: 0,
    transition: { duration: duration },
  },
};
