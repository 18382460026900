// import phone from "../../assets/images/call.png";
// import location from "../../assets/images/location.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";

const Footer = () => {
  return (
    <footer className="pb-[7rem]">
      <div className="lgC:w-[60%]  w-[90%] m-auto flex justify-between flex-col md:flex-row items-center">
        <div>
          {/* <div className="flex gap-4 items-center pb-4">
            <img src={phone} alt="" className="shrink-0 w-[2.7rem]" />
            <p className="text-xl font-nunito text-primary font-[500]">
              <a href="tel:+00 9999 9999" className="no-underline text-primary">
                +00 9999 9999
              </a>
            </p>
          </div> */}
          {/* <div className="flex gap-4 items-center">
            <img src={location} alt="" className="shrink-0 w-[2.7rem]" />
            <p className="text-xl font-nunito text-primary font-[500]">
              San Luis Potosí, México
            </p>
          </div> */}
        </div>
        <div className="md:self-end pt-20 md:pt-0">
          <div className="flex items-center gap-3">
            <a href="https://twitter.com/vibingstudios" target="_blank" rel="noreferrer">
              <img src={twitter} alt="twitter" className="w-[2.7rem]" />
            </a>
            <a href="https://mx.linkedin.com/company/vibingstudios" target="_blank" rel="noreferrer">
              <img src={linkedin} alt="linkedin" className="w-[2.7rem]" />
            </a>
            <h2 className="font-nunito text-primary md:text-4xl text-2xl">
              <span className="font-black">vibing.</span>
              {/* <span className="font-[500]">studios</span> */}
            </h2>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
