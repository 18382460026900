import React from "react";

const BannerSlogan = ({ className, title }) => {
  return (
    <>
      <h2
        className={`${className} font-nunito text-white font-black text-xl mdMax:text-base transform -translate-x-1/2 capitalize`}
      >
        {title}
      </h2>
    </>
  );
};

export default BannerSlogan;
