import bannerImg from "../../assets/images/bannerImg2.png";
import aboutBanner from "../../assets/images/aboutBanner.png";

const Banner = ({ from }) => {
  return (
    <section
      className="relative w-full h-full pointer-events-none"
      data-aos=""
    >
      <div
        className={`w-full h-full flex justify-center md:pt-[9rem] pt-[5rem] flex-col items-center ${
          from !== "about" ? "md:-mb-[6rem] -mb-0" : ""
        }`}
      >
        {from !== "about" ? (
          <img
            src={bannerImg}
            alt="vibing"
            className="lg:w-[90%] w-[95%]  m-auto"
          />
        ) : (
          <img
            src={aboutBanner}
            alt="vibing"
            className="lg:w-[67%] w-full pt-8  m-auto"
          />
        )}
      </div>
    </section>
  );
};

export default Banner;
