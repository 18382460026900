import Title from "../Common/Title";

// import balloonsville from "../../assets/images/balloonsBellaSmall.png";
import kindynos from "../../assets/images/kindynos.png";
import art from "../../assets/images/art.png";
import magic from "../../assets/images/magic.png";
import doggos from "../../assets/images/doggos.png";
import famous from "../../assets/images/famousFox.png";
import solana from "../../assets/images/solana.png";
import moonRank from "../../assets/images/moonRank.png";
import howRare from "../../assets/images/howRare.png";
import FTX from "../../assets/images/FTX.png";
import openSea from "../../assets/images/openSea.png";

const Partnerships = () => {
  const logos = [
    // balloonsville,
    kindynos,
    art,
    magic,
    doggos,
    famous,
    solana,
    moonRank,
    howRare,
    FTX,
    openSea,
  ];

  return (
    <section>
      <div className="text-center">
        <Title title={"Partnerships"} data-aos="fade-up" />
        <p className="pageDes" data-aos="fade-up">
          At Vibing Studios we love to contribute to the enhancement of the
          blockchain ecosystem. Joining forces with talented developers from
          around the world!
        </p>
        <div className="h-0.7 w-full bg-primary backdrop-blur-lg"></div>
        <div className=" w-full bg-tertiary border-t-4 border-primary shadow-lg shadow-primaryLight mb-20">
          <div className="lg:w-[60%] md-[80%] w-full m-auto grid lg:grid-cols-3 md:grid-cols-2 gap-8 py-20">
            {logos.map((logo, index) => (
              <div className="w-full px-20 py-2" key={index}>
                <img src={logo} alt="" className="w-full" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partnerships;
