import Title from "../Common/Title";

const OurMission = () => {
  // For Adding more Paragraphs just add another string to the array
  const data = [
    "Spread the good vibes.",
    "We believe in having fun, empowering others and fueling representation on the metaverse. Our main goal is to bring good vibes to our users, clients, while we deliver a quaility product while we are at it.",
  ];

  return (
    <section>
      <div className="pb-16 text-center" data-aos="">
        <Title title={"Our Mission"} />
      </div>
      <div className="text-container">
        {data.map((item, index) => {
          return (
            <p
              key={index}
              className={data.length - 1 === index ? "" : "pb-8"}
              data-aos=""
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="flex justify-center md:py-32 py-[2rem]" data-aos="">
        {/* <button className="primaryButton">Read More</button> */}
      </div>
    </section>
  );
};

export default OurMission;
