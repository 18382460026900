import wave from "../../assets/images/waveSVG.svg";

const Devider = () => {
  return (
    <>
      <div
        className="lg:w-[80%] w-[95%] m-auto lg:pb-40 md:pb-32 pb-16 lg:pt-[12rem] md:pt-[12rem] pt-[6rem]"
        data-aos=""
      >
        <img src={wave} alt="" className="w-full" />
      </div>
    </>
  );
};

export default Devider;
