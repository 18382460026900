// import Title from "../Common/Title";
// import SecondaryTitle from "../Common/SecondaryTitle";
// import inovatoinsImg from "../../assets/images/marketplace.jpg";
// import vLogo from "../../assets/images/v-1024.png";
// import bannerImg from "../../assets/images/bannerImg2.png";

import v from "../../assets/images/v.png";
import vWhite from "../../assets/images/vWhite2.png";
import i1 from "../../assets/images/i1.png";
import i1White from "../../assets/images/i1White2.png";
import b from "../../assets/images/b.png";
import bWhite from "../../assets/images/bWhite2.png";
import i2 from "../../assets/images/i2.png";
import i2White from "../../assets/images/i2White2.png";
import n from "../../assets/images/n.png";
import nWhite from "../../assets/images/nWhite2.png";
import g from "../../assets/images/g.png";
import gWhite from "../../assets/images/gWhite2.png";
import dot from "../../assets/images/dot.png";
import dotWhite from "../../assets/images/dotWhite2.png";
import BannerSlogan from "../Common/BannerSlogan";

import vibingPass from "../../assets/images/vibingPass.png";

import "../../styles/VibingHome.css";

const Inovations = ({ from }) => {
  return (
    <section className="relative">
      <div
        className={`w-full h-screen flex justify-center flex-col relative items-center bg-primary`}
      >
        {/* <div className="w-full h-screen z-[-10] absolute"></div> */}
        <div className="iconsContainer flex items-end relative smMax:left-[1rem] left-[2rem] bannerImgContainer">
          <div className="iconVIContainer flex items-end relative z-10">
            <div className="iconVContainer w-[11.3rem] custom1100:w-[8.2rem] laptopS:w-[6.1rem] mdMax:w-[7rem] smMax:w-[4.4rem] relative left-[8.2rem] custom1100:left-[6rem] laptopS:left-[4.3rem] mdMax:left-[4.7rem] smMax:left-[2.9rem] duration-500">
              <img
                src={vWhite}
                alt=""
                className="iconV w-full duration-500 cursor-pointer"
              />
              <img
                src={v}
                alt=""
                className="iconVColor w-full duration-500 cursor-pointer absolute top-0 left-0"
              />

              <BannerSlogan
                className={"weBuild left-[83%] w-[6rem]"}
                title="We Build."
              />
            </div>
            <div className="iconIContainer w-[6.45rem] custom1100:w-[4.8rem] laptopS:w-[3.55rem] mdMax:w-[4.1rem] smMax:w-[2.55rem] relative left-[5.5rem] custom1100:left-[3.9rem] laptopS:left-[2.9rem] mdMax:left-[3.1rem] smMax:left-[1.9rem] z-10">
              <img
                src={i1White}
                alt=""
                className="iconI1 w-full duration-500 cursor-pointer"
              />
              <img
                src={i1}
                alt=""
                className="iconIColor w-full duration-500 cursor-pointer absolute top-0 left-0"
              />
            </div>
          </div>
          <div className="iconBIContainer flex items-end relative">
            <BannerSlogan
              className={"weCreate left-[65%] w-[7rem]"}
              title="We Create."
            />
            <div className="iconBContainer w-[11.7rem] custom1100:w-[8.5rem] laptopS:w-[6.3rem] mdMax:w-[7.25rem] smMax:w-[4.5rem] relative left-[3rem] custom1100:left-[2.1rem] laptopS:left-[1.65rem] mdMax:left-[1.7rem] smMax:left-[1.1rem] z-10">
              <img
                src={bWhite}
                alt=""
                className="iconB w-full duration-500 cursor-pointer"
              />
              <img
                src={b}
                alt=""
                className="iconBColor w-full duration-500 cursor-pointer absolute top-0 left-0"
              />
            </div>
            <div className="w-[6.4rem] custom1100:w-[4.8rem] mdMax:w-[4.1rem] laptopS:w-[3.5rem] smMax:w-[2.55rem] relative iconI2Container z-[11]">
              <img
                src={i2White}
                alt=""
                className="iconI2 w-full duration-500 cursor-pointer"
              />
              <img
                src={i2}
                alt=""
                className="iconI2Color w-full duration-500 cursor-pointer absolute top-0 left-0"
              />
            </div>
          </div>
          <div className="iconNGContainer flex items-end relative">
            <BannerSlogan
              className={"weVibe left-[32%] w-[7rem]"}
              title="We Vibe."
            />
            <div className="iconNContainer w-[11.35rem] custom1100:w-[8.5rem] laptopS:w-[6.3rem] mdMax:w-[7.3rem] smMax:w-[4.5rem] relative right-[2.5rem] custom1100:right-[1.8rem] laptopS:right-[1.3rem] mdMax:right-[1.4rem] smMax:right-[0.9rem] z-[12]">
              <img
                src={nWhite}
                alt=""
                className="iconN w-full duration-500 cursor-pointer"
              />
              <img
                src={n}
                alt=""
                className="iconNColor w-full duration-500 cursor-pointer absolute top-0 left-0"
              />
            </div>
            <div className="iconGContainer relative top-[1.5rem] w-[13.2rem] custom1100:w-[10.3rem] laptopS:w-[8rem] mdMax:w-[9.0rem] smMax:w-[6.3rem] right-[5.4rem] custom1100:right-[4rem] laptopS:right-[2.95rem] mdMax:right-[3.1rem] smMax:right-[2.1rem] z-[15]">
              <img
                src={gWhite}
                alt=""
                className="iconG w-full duration-500 cursor-pointer"
              />
              <img
                src={g}
                alt=""
                className="iconGColor w-full duration-500 cursor-pointer absolute top-0 left-0"
              />
            </div>
          </div>
          <div className="iconDotContainer relative bottom-[0.2rem] w-[5.2rem] custom1100:w-[4.5rem] laptopS:w-[2.7rem] mdMax:w-[2.8rem] smMax:w-[2.2rem] right-[10.4rem] custom1100:right-[7.2rem] laptopS:right-[5.8rem] mdMax:right-[6.2rem] smMax:right-[4.2rem] z-[20]">
            <img
              src={dotWhite}
              alt=""
              className="iconDot w-full duration-500 cursor-pointer"
            />
            <img
              src={dot}
              alt=""
              className="iconDotColor w-full duration-500 cursor-pointer absolute top-0 left-0"
            />
          </div>
        </div>
        <div className="font-nunito text-white flex flex-col items-center text-xl mdMax:text-sm font-bold gap-2.5 laptopS:gap-2 pt-[1.7rem] laptopS:pt-[1.2rem]">
          <h2 className="font-black text-3xl laptopS:text-2xl mdMax:text-xl smMax:text-base">
            next-gen creative studios.
          </h2>
          {/* <p className="tracking-[0.1rem] text-center">art • experiences • videogames</p> */}
          <p className="tracking-[0.1rem] text-center">collectibles • animation • videogames</p>
          
          {/* <p className="tracking-[0.1rem] text-center"></p> */}
        </div>
        <div className="w-[19rem] laptopS:w-[15rem] smMax:w-[10rem] absolute right-[2rem] lg:bottom-[2rem] bottom-[1.5rem] smMax:bottom-[1.7rem]">
          <a
            href="https://magiceden.io/marketplace/vibingstudios"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            <img src={vibingPass} alt="" className="w-full vibingPass" />
          </a>
        </div>
        <div className="font-nunito absolute left-[2rem] bottom-[3.5rem] smMax:bottom-[2.7rem] text-white text-sm smMax:text-xs font-medium">
          <p>&copy; 2023, Vibing Studios</p>
          <p>Let's F*cking Vibe.</p>
        </div>
      </div>
    </section>
  );
};

export default Inovations;
