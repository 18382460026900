import SecondaryTitle from "../Common/SecondaryTitle";

const VibingStudios = () => {
  // For Adding more Paragraphs just add another string to the array
  const data = [
    "Be the leading development & art studio on blockchain, full of creatives and knowledgeable individuals.",
    "We will achieve this by focusing on creating quaility products that have a lasting impact on communities. Such as our play-and-earn experiences, unique NFT collections and compromise to spreading good vibes on the metaverse.",
  ];

  return (
    <>
      <div className="text-container pb-[4rem]">
        <div className="pt-6 md:pt-0" data-aos="">
          <SecondaryTitle title={""} />
        </div>
        {data.map((item, index) => {
          return (
            <p
              key={index}
              className={data.length - 1 === index ? "" : "pb-8"}
              data-aos=""
            >
              {item}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default VibingStudios;
