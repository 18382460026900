import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

const TeamViscat = () => {
  const { unityProvider } = useUnityContext({
    loaderUrl: "/public/GameJam/TeamViscat/Build/Build.loader.js",
    dataUrl: "/public/GameJam/TeamViscat/Build/Build.data",
    frameworkUrl: "/public/GameJam/TeamViscat/Build/Build.framework.js",
    codeUrl: "/public/GameJam/TeamViscat/Build/Build.wasm",
  });

  return (
    <div>
        <Unity unityProvider={unityProvider}
         style={{ height: 720, width: 960 }}
        />
    </div>
    );
}

export default TeamViscat;