import { useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

import Footer from "../../components/Common/Footer";
import Header from "../../components/Common/Header";

import RequiredInput from "../../components/Common/RequiredInput";
import Title from "../../components/Common/Title";
import { animationVariants } from "../../hooks/animationVariants";

const ContactUs = () => {
  const [inputtedData, setInputtedData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputtedData({ ...inputtedData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await fetch("https://shielded-reaches-45624.herokuapp.com/send/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputtedData),
    });

    toast.success("Your message has been sent successfully!");

    document.getElementById("contact-form").reset();
    // const response = await sendEmail.json();
  };

  return (
    <motion.div
      variants={animationVariants}
      initial="initial"
      animate="enter"
      exit="exit"
      className="w-full h-full"
    >
      <Header />
      <section className="h-full">
        <div className="text-center py-[5rem]">
          <Title title={"Contact Us."} />
          <p className="font-nunito text-lg font-medium flex flex-col items-center">
            Want to work with us? Collaborate with us?{" "}
            <span>
              Fill in the form below and one of our collab managers will contact
              you as soon as possible!
            </span>
          </p>
        </div>
        <div className="lg:w-[60%] md:w-[70%] sm:w-[80%] w-[90%] m-auto">
          <form onSubmit={handleSubmit} id="contact-form">
            <div className="pb-4">
              <RequiredInput
                name={"name"}
                type="text"
                label={"Your Name"}
                event={handleInputChange}
                placeholder="Put Your Name Here"
              />
            </div>
            <div className="pb-4">
              <RequiredInput
                name={"email"}
                type="email"
                label={"Your Email"}
                event={handleInputChange}
                placeholder="Put Your Email Here"
              />
            </div>
            <div className="pb-4">
              <RequiredInput
                name={"subject"}
                type="text"
                label={"Subject"}
                event={handleInputChange}
                placeholder="Put Subject Here"
              />
            </div>
            <div className="">
              <p>
                <label
                  htmlFor={"message"}
                  className="bg-primary text-white lg:text-[1.5rem] text-[1.2rem] font-nunito font-medium uppercase p-1"
                >
                  Your Message
                </label>
              </p>
              <textarea
                rows={4}
                name="message"
                id="experienceDescription"
                className="block w-full border-primary md:text-xl text-base font-nunito py-2 px-3 focus:outline-none placeholder:text-primary h-[15rem] resize-y text-primary"
                defaultValue={""}
                onChange={handleInputChange}
                placeholder="Put Your Message Here"
              />
            </div>

            <div className="flex justify-center py-20">
              <button className="primaryButton">Submit</button>
            </div>
          </form>
        </div>
        <Footer />
      </section>
    </motion.div>
  );
};

export default ContactUs;
