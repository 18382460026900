const RequiredInput = ({ name, label, type, placeholder, event }) => {
  return (
    <>
      <>
        <p>
          <label
            htmlFor={name}
            className="bg-primary text-white md:text-[1.5rem] text-[1.2rem] font-nunito font-medium uppercase p-1"
          >
            {label}
          </label>
        </p>
        <input
          type={type}
          name={name}
          className="w-full border border-primary text-primary font-nunito md:text-xl text-base py-2 px-3 focus:outline-none placeholder:text-primary"
          placeholder={placeholder}
          required
          onChange={event}
        />
      </>
    </>
  );
};

export default RequiredInput;
