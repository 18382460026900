import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MenuIcon } from "@heroicons/react/solid";

import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";

import logo from "../../assets/images/vibelogoBlue.png";
import logoWhite from "../../assets/images/vibelogo.png";
import MobileNavbar from "./MobileNavbar";
import { Link } from "react-router-dom";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const items = [
    // {
    //   name: "Home.",
    //   link: "/",
    // },
    // {
    //   name: "About Us.",
    //   link: "/about",
    // },
    {
      name: "Our Art.",
      link: "/gallery",
    },
    // {
    //   name: "Contact Us.",
    //   link: "/contact",
    // },
  ];

  return (
    <header
      className={`w-full relative ${
        location.pathname === "/" ? "bg-bg" : "bg-primary"
      }`}
    >
      <div className="w-[87%] m-auto flex justify-between items-center h-24 navBreakpoint:py-0">
        <Link to={"/"} className="sm:w-[10rem] w-[8rem]">
          <img
            src={location.pathname === "/" ? logo : logoWhite}
            alt="logo"
            className="w-full"
          />
        </Link>
        <div className="items-center flex">
          <nav className="no-underline hidden navBreakpoint:flex">
            {items.map((item, index) => (
              <NavLink
                to={item.link}
                className={({ isActive }) =>
                  ` ${
                    location.pathname === "/"
                      ? "navBreakpoint:text-primary hover:before:bg-primary"
                      : "navBreakpoint:text-bg hover:before:bg-bg"
                  } text-white ${
                    !isActive
                      ? "navLinkStyle nav-link duration-500"
                      : `navLinkStyle relative nav-link-active ${
                          location.pathname === "/"
                            ? "before:bg-primary"
                            : "before:bg-bg"
                        }`
                  }`
                }
                onClick={() => setSidebarOpen(false)}
                end={index === 0 ? true : false}
              >
                {item.name}
              </NavLink>
            ))}
          </nav>
          <div className="flex items-center relative justify-center gap-8 z-[100] pl-4">
            <div className="flex items-center gap-3">
              <a
                href="https://twitter.com/vibingstudios"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="twitter" className="w-[2rem]" />
              </a>
              <a
                href="https://mx.linkedin.com/company/vibingstudios"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin} alt="linkedin" className="w-[2rem]" />
              </a>
            </div>
            <p className="navBreakpoint:hidden h-[5rem] block">
              <MenuIcon
                onClick={() => setSidebarOpen(true)}
                className={`top-4 relative cursor-pointer ${
                  location.pathname === "/" ? "text-primary" : "text-bg"
                } w-12 h-12 z-[100] `}
              />
            </p>
          </div>
        </div>
      </div>
      <MobileNavbar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        items={items}
      />
      {/* Three Waves */}
      <div
        className={`dropOne ${
          location.pathname === "/" ? "bg-bg" : "bg-primary"
        } w-10 h-24 rounded-full absolute bottom-[-45%] right-[80px]`}
      ></div>
      <div
        className={`dropTwo ${
          location.pathname === "/" ? "bg-bg" : "bg-primary"
        } w-12 h-44 rounded-full absolute bottom-[-100%] right-[35px]`}
      ></div>
      <div
        className={`dropThree ${
          location.pathname === "/" ? "bg-bg" : "bg-primary"
        } w-10 h-32 rounded-full absolute bottom-[-60%] right-[-0px]`}
      ></div>
    </header>
  );
};

export default Header;
