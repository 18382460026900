import Title from "../Common/Title";
import SecondaryTitle from "../Common/SecondaryTitle";

import ourCollectionOne from "../../assets/images/collectionOne.png";
import ourCollectionTwo from "../../assets/images/collectionTwo2.png";
import ourCollectionThree from "../../assets/images/collectionThree3.jpg";
import bitmon from "../../assets/images/bitmon.png";
import kingdom from "../../assets/images/kingdom.png";
import monkettes from "../../assets/images/monkettes.png";

const OurCollections = () => {
  return (
    <section className="" id="ourCollectios">
      <div className="text-center" data-aos="fade-up">
        <Title title={"Our Projects"} />
        <p className="pageDes" data-aos="fade-up">
          Vibing Studios projects include a collection of 3333 NFTs airdropped to MonkeDAO
          Solana blockchain called Monkettes, a play-and-earn videogame where
          you can breed, trade, and fight with origina creatures, and a
          soon to be launched NFT collection of high-quality art under the name
          Vibe Kingdom.
        </p>
      </div>
      {/* KINGDOM AREA */}
      <div className="my-20">
        <img src={ourCollectionThree} alt="Collection" className="w-full" />
      </div>
      <div className="lg:w-[60%] w-[90%] m-auto grid md:grid-cols-2 grid-cols-1 gap-6">
        <div className="flex flex-col justify-center" data-aos="zoom-in-up">
          <SecondaryTitle title={"Vibe Kingdom"} />
          <p className="pt-4 font-nunito font-[500] leading-[1.7] md:w-[80%] w-full text-justify">
            A collection of NFTs known as Vibe Knights – unique digital
            collectibles. Each Vibe Kingdom NFT grants access to the Kingdom, a closed community whose mission is to Vibe the metaverse.
        
          </p>
          <div className="pt-14">
            <a href="https://vibing.studio/" target={"blank"}>
              {/* <button className="primaryButton">Website</button> */}
            </a>
          </div>
        </div>
        <div data-aos="zoom-in-down">
          <img src={kingdom} alt="" className="w-full" />
        </div>
      </div>
      {/* END OF KINGDOM AREA */}
      {/* BITMON AREA */}
      <div className="my-20">
        <img src={ourCollectionOne} alt="Collection" className="w-full" />
      </div>
      <div className="lg:w-[60%] w-[90%] m-auto grid md:grid-cols-2 grid-cols-1 gap-6">
        <div data-aos="zoom-in-down">
          <img src={bitmon} alt="" className="w-full" />
        </div>
        <div className="flex flex-col justify-center" data-aos="zoom-in-up">
          <SecondaryTitle title={"Bitmon World"} />
          <p className="pt-4 font-nunito font-[500] leading-[1.7] md:w-[80%] w-full text-justify">
            <b>Bitmon Adventures</b> is a play-and-earn on
            Solana blockchain where you can breed, trade and fight with unique
            NFT creatures. 
            <br></br>
            <br></br>
            <b>Bitmon World</b> is the ecosystem built around our Play-and-Earn
            game and its collection of 10,000 NFTs known as Bitmon Trainers.
            Each Bitmon Trainer NFT grants daily $BIT, which is the token that powers our economy.
          </p>
          <div className="pt-14">
            <a href="https://bitmon.io/" target={"blank"}>
              <button className="primaryButton">Website</button>
            </a>
          </div>
        </div>
      </div>
      {/* END OF BITMON AREA */}
      {/* MONKETTES AREA */}
      <div className="md:mb-40 mb-20 md:mt-56 mt-20 shadow-primaryShadow shadow-lg">
        <img src={ourCollectionTwo} alt="" className="w-full " />
      </div>
      <div className="lg:w-[60%] w-[90%] grid md:grid-cols-2 grid-cols-1 gap-6 m-auto">
        <div className="flex flex-col justify-center" data-aos="zoom-in-down">
          <SecondaryTitle title={"Monkettes"} />
          <p className="pt-4 font-nunito font-[500] leading-[1.7] md:w-[80%] w-full text-justify">
            A unique collection of <b>3333</b> NFTs known as <b>Monkettes</b> – unique digital
            collectibles airdropped for free to MonkeDAO.<br></br> <br></br>  Each Monkette NFT grants access to the Vibing DAO, a
            knowledge exchange community based on Web3.<br></br> 
          </p>
          <div className="pt-14">
            <a href="https://monkettes.io/" target={"blank"}>
              <button className="primaryButton">Website</button>
            </a>
          </div>
        </div>
        <div>
          <img
            src={monkettes}
            alt=""
            className="w-full"
            data-aos="zoom-in-up"
          />
        </div>
      </div>
      {/* END OF MONKETTES AREA */}
    </section>
  );
};

export default OurCollections;
