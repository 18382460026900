import React from "react";
import { motion } from "framer-motion";
import { XIcon } from "@heroicons/react/solid";

import Header from "../../components/Common/Header";
import Title from "../../components/Common/Title";

import img1 from "../../assets/images/gallery/1.png";
import img2 from "../../assets/images/gallery/2.png";
import img3 from "../../assets/images/gallery/3.png";
import img4 from "../../assets/images/gallery/4.png";
import img5 from "../../assets/images/gallery/5.png";
import img6 from "../../assets/images/gallery/6.png";
import img7 from "../../assets/images/gallery/7.png";
import img8 from "../../assets/images/gallery/8.png";
import img9 from "../../assets/images/gallery/9.png";
import img10 from "../../assets/images/gallery/10.png";
import img11 from "../../assets/images/gallery/11.png";
import img12 from "../../assets/images/gallery/12.png";
import img13 from "../../assets/images/gallery/13.jpg";
import img14 from "../../assets/images/gallery/14.png";
import img15 from "../../assets/images/gallery/15.png";
import img16 from "../../assets/images/gallery/16.png";
import img17 from "../../assets/images/gallery/17.png";
import img18 from "../../assets/images/gallery/18.png";
import img19 from "../../assets/images/gallery/19.png";
import img20 from "../../assets/images/gallery/20.png";
import img21 from "../../assets/images/gallery/21.png";
import img22 from "../../assets/images/gallery/22.png";
import img23 from "../../assets/images/gallery/23.jpg";
import img24 from "../../assets/images/gallery/24.jpeg";
import img25 from "../../assets/images/gallery/25.jpg";
import img26 from "../../assets/images/gallery/26.jpg";
import img27 from "../../assets/images/gallery/27.jpg";
import img28 from "../../assets/images/gallery/28.jpg";
import img29 from "../../assets/images/gallery/29.jpg";
import img30 from "../../assets/images/gallery/30.png";
import img31 from "../../assets/images/gallery/31.jpg";
import img32 from "../../assets/images/gallery/32.png";
import img33 from "../../assets/images/gallery/33.png";
import img34 from "../../assets/images/gallery/34.png";
import img35 from "../../assets/images/gallery/35.png";
import img36 from "../../assets/images/gallery/36.png";
import img37 from "../../assets/images/gallery/37.png";
import img38 from "../../assets/images/gallery/38.png";
import img40 from "../../assets/images/gallery/40.png";
import img41 from "../../assets/images/gallery/41.png";
import img42 from "../../assets/images/gallery/42.png";
import img43 from "../../assets/images/gallery/43.png";
import img44 from "../../assets/images/gallery/44.png";
import img45 from "../../assets/images/gallery/45.png";
import img46 from "../../assets/images/gallery/46.png";
import img47 from "../../assets/images/gallery/47.png";
import img48 from "../../assets/images/gallery/48.png";
import img49 from "../../assets/images/gallery/49.png";
import img50 from "../../assets/images/gallery/50.png";
import img51 from "../../assets/images/gallery/51.png";
import img52 from "../../assets/images/gallery/52.png";
import img53 from "../../assets/images/gallery/53.png";
import img54 from "../../assets/images/gallery/54.png";
import img55 from "../../assets/images/gallery/55.png";
import img56 from "../../assets/images/gallery/56.png";
import img57 from "../../assets/images/gallery/57.png";
import img58 from "../../assets/images/gallery/58.png";
import img59 from "../../assets/images/gallery/59.png";

import "./VibingGallery.css";
import { animationVariants } from "../../hooks/animationVariants";

const VibingGallery = () => {
  const [imageActive, setImageActive] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  const images = [
    {
      src: img1,
      caption: "Image 1",
    },
    {
      src: img2,
      caption: "Image 2",
    },
    {
      src: img3,
      caption: "Image 3",
    },
    {
      src: img4,
      caption: "Image 4",
    },
    {
      src: img5,
      caption: "Image 5",
    },
    {
      src: img6,
      caption: "Image 6",
    },
    {
      src: img7,
      caption: "Image 7",
    },
    {
      src: img8,
      caption: "Image 8",
    },
    {
      src: img9,
      caption: "Image 9",
    },
    {
      src: img10,
      caption: "Image 10",
    },
    {
      src: img11,
      caption: "Image 11",
    },
    {
      src: img12,
      caption: "Image 12",
    },
    {
      src: img13,
      caption: "Image 13",
    },
    {
      src: img14,
      caption: "Image 14",
    },
    {
      src: img15,
      caption: "Image 15",
    },
    {
      src: img16,
      caption: "Image 16",
    },
    {
      src: img17,
      caption: "Image 17",
    },
    {
      src: img18,
      caption: "Image 18",
    },
    {
      src: img19,
      caption: "Image 19",
    },
    {
      src: img20,
      caption: "Image 20",
    },
    {
      src: img21,
      caption: "Image 21",
    },
    {
      src: img22,
      caption: "Image 22",
    },
    {
      src: img23,
      caption: "Image 23",
    },
    {
      src: img24,
      caption: "Image 24",
    },
    {
      src: img25,
      caption: "Image 25",
    },
    {
      src: img26,
      caption: "Image 26",
    },
    {
      src: img27,
      caption: "Image 27",
    },
    {
      src: img28,
      caption: "Image 28",
    },
    {
      src: img29,
      caption: "Image 29",
    },
    {
      src: img30,
      caption: "Image 30",
    },
    {
      src: img31,
      caption: "Image 31",
    },
    {
      src: img32,
      caption: "Image 32",
    },
    {
      src: img33,

      caption: "Image 33",
    },
    {
      src: img34,
      caption: "Image 34",
    },
    {
      src: img35,
      caption: "Image 35",
    },
    {
      src: img36,
      caption: "Image 36",
    },
    {
      src: img37,
      caption: "Image 37",
    },
    {
      src: img38,
      caption: "Image 38",
    },
    {
      src: img40,
      caption: "Image 40",
    },
    {
      src: img41,
      caption: "Image 41",
    },
    {
      src: img42,
      caption: "Image 42",
    },
    {
      src: img43,
      caption: "Image 43",
    },
    {
      src: img44,
      caption: "Image 44",
    },
    {
      src: img45,
      caption: "Image 45",
    },
    {
      src: img46,
      caption: "Image 46",
    },
    {
      src: img47,
      caption: "Image 47",
    },
    {
      src: img48,
      caption: "Image 48",
    },
    {
      src: img49,
      caption: "Image 49",
    },
    {
      src: img50,
      caption: "Image 50",
    },
    {
      src: img51,
      caption: "Image 51",
    },
    {
      src: img52,
      caption: "Image 52",
    },
    {
      src: img53,
      caption: "Image 53",
    },
    {
      src: img54,
      caption: "Image 54",
    },
    {
      src: img55,
      caption: "Image 55",
    },
    {
      src: img56,
      caption: "Image 56",
    },
    {
      src: img57,
      caption: "Image 57",
    },
    {
      src: img58,
      caption: "Image 58",
    },
    {
      src: img59,
      caption: "Image 59",
    },
  ];

  const getImg = (img) => {
    setImageActive(img);
    setIsOpen(true);
  };

  return (
    <motion.div
      variants={animationVariants}
      initial="initial"
      animate="enter"
      exit="exit"
      className="w-full h-full"
    >
      <Header />
      <div className={`${isOpen ? "modal modalOpen" : "modal"}`}>
        <img src={imageActive} alt="" />
        <XIcon className="modalClose" onClick={() => setIsOpen(false)} />
      </div>
      <section className="w-full min-h-screen bg-bg pb-[3rem]">
        <div className="text-center pb-[7rem] pt-[3rem]">
          <Title title={"Our Art."} />
        </div>
        <div className="gallery lg:w-[85%] w-[95%] m-auto h-full">
          {images.map((img, index) => (
            <div
              key={index}
              className="pics"
              onClick={() => {
                getImg(img.src);
              }}
            >
              <img
                src={img.src}
                alt={img.caption}
                className="w-full"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </section>
    </motion.div>
  );
};

export default VibingGallery;
