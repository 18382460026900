const SecondaryTitle = ({ title }) => {
  return (
    <>
      <h2 className="font-nunito font-[900] font-bold text-primary text-[2.8rem]">
        {title}
      </h2>
    </>
  );
};

export default SecondaryTitle;
