import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Link, NavLink } from "react-router-dom";

const MobileNavbar = ({ setSidebarOpen, sidebarOpen, items }) => {
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[9999] flex navBreakpoint:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-[15rem] md:max-w-[20rem] flex-1 flex-col bg-primary pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-[3rem] right-[-1rem] -mr-12 pt-2 z-[9999]">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full bg-transparent border-none focus:outline-none "
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XIcon
                      className="h-8 w-8 text-white  cursor-pointer"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <h2 className="text-2xl font-bold text-white">
                  <Link
                    to={"/"}
                    className="no-underline"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <h2 className="font-nunito text-white md:text-4xl text-2xl">
                      <span className="font-black">vibing. </span>
                      <span className="font-[500]">studios</span>
                    </h2>
                  </Link>
                </h2>
              </div>
              <nav
                className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="space-y-1 px-2">
                  {items.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.link}
                      style={{
                        margin: "2rem 0",
                      }}
                      className={({ isActive }) =>
                        !isActive
                          ? "navLinkStyle text-bg nav-link duration-500 group flex"
                          : "navLinkStyle text-bg relative nav-link-active group flex"
                      }
                      onClick={() => setSidebarOpen(false)}
                      end={index === 0 ? true : false}
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </nav>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default MobileNavbar;
