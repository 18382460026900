import React from "react";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import About from "../pages/About/About";
import ContactUs from "../pages/ContactUs/ContactUs";
import VibingGallery from "../pages/Gallery/VibingGallery";
import Home from "../pages/Home/Home";

import TeamFosgui from "../pages/GameJam/TeamFosgui/TeamFosgui";
import TeamViscat from "../pages/GameJam/TeamViscat/TeamViscat";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path={"/"} element={<Home />} />
        <Route path={"/ourGames"} element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<VibingGallery />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/GameJam/TeamFosgui" element = {<TeamFosgui />} />
        <Route path="/GameJam/TeamViscat" element = {<TeamViscat />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
