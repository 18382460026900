const Title = ({ title }) => {
  const separated = title.split(" ");
  return (
    <>
      <h2 className="md:text-[3.3rem] text-[2.3rem] font-montserrat font-extrabold">
        {separated.length > 1 ? (
          <>
            <span className="text-secondary">{separated[0]}</span>{" "}
            <span className="text-primary">{separated[1]}</span>
          </>
        ) : (
          <span className="text-primary">{separated}</span>
        )}
      </h2>
    </>
  );
};

export default Title;
