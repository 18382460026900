import { motion } from "framer-motion";

import { animationVariants } from "../../hooks/animationVariants";

import Banner from "../../components/Common/Banner";
import Devider from "../../components/Common/Devider";
import Footer from "../../components/Common/Footer";
import OurCollections from "../../components/HomeComponents/OurCollections";
import Partnerships from "../../components/HomeComponents/Partnerships";
import VibingStudios from "../../components/HomeComponents/VibingStudios";
import OurMission from "../../components/HomeComponents/OurMission";
import Header from "../../components/Common/Header";

const About = () => {
  return (
    <motion.div
      className="w-full h-full"
      variants={animationVariants}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <Header />
      <Banner />
      <VibingStudios />
      <OurMission />
      <Devider />
      <OurCollections />
      <Devider />
      <Partnerships />
      <Devider />
      <Footer />
    </motion.div>
  );
};

export default About;
