import { ToastContainer } from "react-toastify";
import ScrollToTop from "react-scroll-to-top";
import AOS from "aos";

import "aos/dist/aos.css";
import "./App.css";

import "./Grid/bootstrap-grid.min.css";

import arrowUp from "./assets/images/arrowUp.png";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  AOS.init({ duration: 1800 });

  return (
    <div className="overflow-hidden bg-bg">
      {/* <Header /> */}
      <AnimatedRoutes />
      <ToastContainer position="top-center" autoClose={2000} />
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#0066ff",
          borderRadius: "50%",
        }}
        component={
          <div className="flex justify-center items-center">
            <img src={arrowUp} alt="<>" className="w-6" />
          </div>
        }
      />
    </div>
  );
}

export default App;
